import React, { useState } from 'react';
import { Link } from '@mui/material';

import FlagLabel from '../FlagLabel';
import ContactUsDialog from '../ContactUs/ContactUsDialog';

const curatedList = [
  {
    id: 'beachfront',
    title: 'Beachfront',
    image: 'images/curated/beachfront.jpg',
    link: '/search?beachfront=true&preConstruction=true&newConstruction=true&foreclosures=true&shortSales=true&existingCondos=true&forSale=true',
  },
  {
    id: 'petFriendly',
    title: 'Pet Friendly',
    image: 'images/curated/pet-friendly.png',
    link: '/blog/tags/petskidsfamily',
  },
  {
    id: 'shortTermFriendly',
    title: 'Short-term Friendly',
    image: 'images/curated/short-term-friendly.jpg',
    link: '/blog/tags/short-term-rental',
  },
  {
    id: 'waterView',
    title: 'Water View',
    image: 'images/curated/waterview.jpg',
    link: '/search?wfAny=true&preConstruction=true&newConstruction=true&foreclosures=true&shortSales=true&existingCondos=true&forSale=true',
  },
  {
    id: 'preconstruction',
    title: 'Preconstruction',
    image: 'images/curated/preconstruction.jpg',
    link: '/blog/tags/preconstruction',
  },
  {
    id: 'penthouses',
    title: 'Penthouses',
    image: 'images/curated/penthouse.jpg',
    link: '/search?penthouse=true&preConstruction=true&newConstruction=true&foreclosures=true&shortSales=true&existingCondos=true&forSale=true',
  },
];

const CuratedLists: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handlePopUpClose = () => {
    setOpen(false);
  };

  return (
    <section className="container-xl !px-[31px] lg:!px-[42px] pt-3 md:pt-[22px] lg:pt-[33px] pb-[63px] md:pb-[78px] md:px-12 lg:pb-[71px] text-m curated-lists">
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col mb-6 lg:w-[405px] md:flex-row md:ml-[14px] lg:flex-col lg:mr-[42px] lg:mb-0">
          <div className="relative flex flex-col lg:justify-center md:w-1/2 lg:w-auto mb-[-1px] md:mb-10 lg:mb-0">
            <div className="relative flex flex-col justify-center w-max">
              <FlagLabel
                name="Curated lists"
                textSize="md:text-20-26-0.67 xl:text-24-32-0.3 bg-beige"
              />
            </div>
          </div>
          <div className="text-14-21-0.3 mb-[-2px] md:pl-[9px] lg:pl-0 md:w-1/2 lg:w-[410px] lg:text-16-26-0.3">
            <p className="mt-6 md:mt-0 lg:mt-[30px]">
              Our agent team is constantly curating lists of condos that match
              your every need. Your desires are unique, and so is our enthusiasm
              and ability to find you the perfect condo match.
            </p>
            <p className="mt-[21px] lg:mt-[25px] lg:pb-72">
              Don’t find a curated list that fits what you need?
              <span> </span>
              <Link
                href={'#'}
                onClick={() => setOpen(true)}
                className="!text-14-21-0.3 xl:!text-16-26-0.3"
              >
                Let us create a selection of condos specifically for you
              </Link>
              <span> </span>
              or
              <span> </span>
              <Link
                className="!text-14-21-0.3 xl:!text-16-26-0.3"
                href={
                  '/search?preConstruction=true&newConstruction=true&foreclosures=true&shortSales=true&existingCondos=true&forSale=true&sortOption=Recommended'
                }
              >
                use our exclusive condo search technology
              </Link>
            </p>
          </div>
        </div>
        <div className="grid w-full grid-cols-1 lg:w-2/3 md:grid-cols-2 lg:gap-[34px] mt-[18px] md:mt-[55px] lg:mt-0 md:ml-[14px] lg:ml-[7px] md:mb-[21px]">
          {curatedList.map((item) => (
            <div key={item.id} className="mb-[34px] lg:mb-[10px]">
              <a href={item.link} rel="nofollow">
                <div className="h-full border-transparent hover-animation group">
                  <img
                    className="object-cover object-top w-full md:w-[323px] h-[162px] md:h-[166px] lg:w-[419px] lg:h-[215px]"
                    src={item.image}
                    alt={item.title}
                  />
                  <button className="flex justify-between items-center w-full md:w-[323px] lg:w-full max-w-[419px] h-[46px] lg:h-[63px] pl-[22px] md:pl-[23px] pr-2 md:pr-[14px] pt-[7px] md:pt-[12px] uppercase border-2 hover-animation md:pl-8 md:pr-4 lg:py-5 border-gold group-hover:border-gold-hover group-hover:bg-gold-hover font-sohneBreitHalbfett">
                    <div className="text-14-26-0.3 tracking-wide md:text-14-18-0.47 lg:text-16-26-0.3 pt-[1px]">
                      {item.title}
                    </div>
                    <div>
                      <img
                        src="/images/arrow-button.svg"
                        alt="Pet Friendly"
                        className="w-[24px] h-[18px] lg:w-[28px] lg:h-[21px]"
                      />
                    </div>
                  </button>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <ContactUsDialog open={open} onClose={handlePopUpClose} />
    </section>
  );
};

export default CuratedLists;
