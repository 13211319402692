import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { Box, Theme, useMediaQuery } from '@mui/material';

import SearchInput from 'components/Search/SearchInput';
import { SearchBoxFilters } from 'components/Search/SearchInput/types';
import {
  getSearchUrlByType,
  TYPE_RENT,
  TYPE_SALE,
} from 'components/Search/utils/listingsFiltersUtils';
import { PropertyType } from 'components/UnitsFilters/types';

type MainSearchProps = {
  noScroll?: boolean;
};

const MainSearch: React.FC<MainSearchProps> = ({ noScroll }) => {
  const [activeTab, setActiveTab] = useState<PropertyType>('sale');

  const router = useRouter();
  const isHomePage = router.asPath === '/';

  const lessLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const onSelectAutocompleteItem = useCallback(
    (item: SearchBoxFilters) => {
      router.push(getSearchUrlByType(activeTab, { searchBoxFilters: item }));
    },
    [activeTab, router]
  );

  return (
    <div
      className={classnames('md:pl-[58px] lg:pl-[115px]', {
        'md:pl-[48px]': !isHomePage,
        'pl-[30px]': isHomePage,
      })}
    >
      <div className="tabs">
        <button
          className={
            'tab flex w-[89px] md:w-[103px] h-[44px] pb-2 pt-2.5 uppercase font-sohneBuch text-14-21-0.3 lg:text-16-21-0.3 justify-center text-center ' +
            (activeTab === TYPE_SALE
              ? 'selected-tab border-b-0'
              : 'hover:bg-white')
          }
          onClick={(e) => {
            e.preventDefault();
            setActiveTab(TYPE_SALE);
          }}
        >
          Buy
        </button>
        <button
          className={
            'tab flex w-[89px] md:w-[103px] h-[44px] pb-2 pt-2.5 font-sohneBuch text-14-21-0.3 lg:text-16-21-0.3 uppercase justify-center text-center ' +
            (activeTab === TYPE_RENT
              ? 'selected-tab border-b-0'
              : 'hover:bg-white')
          }
          onClick={(e) => {
            e.preventDefault();
            setActiveTab(TYPE_RENT);
          }}
        >
          Rent
        </button>
      </div>
      <Box
        width={['90%', '90%', '81%', `${isHomePage ? '650px' : '84%'}`]}
        sx={{
          background: 'white',
          color: 'black',
          height: `${!lessLg ? '72px' : '57px'}`,
          border: '1px solid #858585',
        }}
      >
        <SearchInput
          size="medium"
          onChangeFilters={onSelectAutocompleteItem}
          noScroll={noScroll}
          activeTab={activeTab}
          className="font-SohneBuch text-16-21-0.3 !placeholder-black !text-black"
          iconPosition="!top-[17px] lg:!top-[25px] !right-[11px] lg:!h-[23px] lg:!w-[23px]"
          homePageClass="home-search-input"
          placeholder="Search Neighborhood, Building, City, Zip or Condo Name"
        />
      </Box>
    </div>
  );
};

export default MainSearch;
