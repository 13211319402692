import React from 'react';
import type { GetStaticProps } from 'next';
import { useRouter } from 'next/router';

import Floorplan from '../components/Home/Floorplan';
import MainSearch from '../components/Search/MainSearch';
import WhatMakeUsUnique from '../components/Home/WhatMakeUsUnique';
import RelatedArticles from '../components/RelatedArticles';

import { Metadata } from 'components/Metadata';
import Articles from '../components/Articles';
import CuratedLists from '../components/Home/CuratedLists';
import LineBetweenSections from '../components/LineBetweenSections';
import { Blog, BlogsResponse } from 'apolloClient/types/Blog';
import { defaultImage } from 'src/constants/constants';
import { client } from '../apolloClient/client';
import { flattenStrapiBulkDataItems } from '../lib/flattenStrapiBulkDataItems';
import { LOAD_HOME_PAGE_BLOGS } from '../apolloClient/queries/blogs';
import { DEFAULT_REVALIDATE_INTERVAL, ORIGIN } from 'src/constants';
import Header from '../components/layouts/Header/Header';
import { Agent, AgentsResponse } from '../apolloClient/types/Agents';
import { LOAD_AGENTS_FOR_EXPERTS_SECTION } from '../apolloClient/queries/agents';
import Experts from '../components/Experts/Experts';
import {
  getSmallImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import GoogleAnalytics from 'src/ga/grouping';

interface BlogTypes {
  blogs: Blog[];
  experts: Agent[];
}

const Home: React.FC<BlogTypes> = ({ blogs, experts }) => {
  const { asPath } = useRouter();

  return (
    <>
      <Metadata
        {...{
          title: `Miami Condos for Sale and Rent | CondoBlackBook`,
          description:
            'Miami Beach and Miami’s ultra-local condo neighborhood resource, with real-time sale and rental availability, pics, condo search, floor plans, recent sales and news updates.',
          url: `${ORIGIN}${asPath}`,
        }}
      />
      <GoogleAnalytics pageGroup={'Other'} />
      <Header />
      <section className="lg:pt-7 text-m container-home h-[363px] md:w-[677px] lg:w-[1024px] xl:w-[1341px] lg:h-[628px] overflow-hidden">
        <div className="w-full px-0 bg-no-repeat bg-cover bg-left-center bg-home-page-img h-[363px] lg:h-145">
          <div
            className={`
              text-24-27-0.64 md:text-27-32-0.72 lg:text-47-54-1.25 uppercase font-sohneBreitHalbfett
              w-[218px] md:w-[377px] lg:w-[655px]
              pt-[24px] md:pt-12 lg:pt-[81px]
              pb-[55px] md:pb-[71px] lg:pb-[169px]
              pl-[30px] md:pl-[58px] lg:pl-[115px]
            `}
          >
            <h1>The Insider’s guide to condos</h1>
          </div>
          <MainSearch />
        </div>
      </section>
      <WhatMakeUsUnique />
      <LineBetweenSections hideLine={'container-xl'} />
      <Experts
        experts={experts}
        flagLabelTextSize="md:text-20-26-0.67 xl:text-24-32-0.3 bg-beige"
        isHomePage
      />
      <LineBetweenSections hideLine={'container-xl'} />
      <Floorplan />
      <LineBetweenSections hideLine={'container-xl'} />
      <CuratedLists />
      <LineBetweenSections hideLine={'container-xl'} />
      <RelatedArticles
        isHomePage
        name="Miami news"
        flexGap="lg:gap-[40px]"
        showButtonMore="mb-[77px]"
        classNameWidth="lg:!w-[421px]"
      >
        {blogs?.map((blog, index) => {
          return (
            <Articles
              href={`/blog/${blog.slug}`}
              key={index}
              topics={blog?.blogTags}
              {...(blog?.mainImage?.data?.attributes
                ? {
                    imageUrl: getSmallImageUrlFromImage(
                      blog?.mainImage?.data?.attributes
                    ),
                    blurredImageUrl: getThumbnailImageUrlFromImage(
                      blog?.mainImage?.data?.attributes
                    ),
                  }
                : {})}
              imageAlt={blog.title}
              title={blog.title}
              description={blog.shortDescription || ''}
            />
          );
        })}
      </RelatedArticles>
    </>
  );
};

export default Home;

export const getStaticProps: GetStaticProps<{ blogs: Blog[] }> = async () => {
  const blogsResponse = await client.query<{ blogs: BlogsResponse }>({
    query: LOAD_HOME_PAGE_BLOGS,
  });

  const expertsResponse = await client.query<{ agents: AgentsResponse }>({
    query: LOAD_AGENTS_FOR_EXPERTS_SECTION,
  });

  return {
    props: {
      blogs: flattenStrapiBulkDataItems(blogsResponse.data.blogs?.data),
      experts: flattenStrapiBulkDataItems(expertsResponse.data.agents?.data),
    },
    revalidate: DEFAULT_REVALIDATE_INTERVAL,
  };
};
