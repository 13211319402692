import React from 'react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import floorplan1 from '/public/images/floorplan-1.jpg';
import floorplan2 from '/public/images/floorplan-2.jpg';
import Button from '../Button';
import FlagLabel from '../FlagLabel';

const Floorplan: React.FC = () => {
  const { asPath } = useRouter();
  const isHomePage = asPath === '/';

  return (
    <>
      {
        <section
          className={classNames(
            'container-xl !px-[31px] md:!px-[44px] lg:!px-[51px] pt-3 pb-8 md:py-3 text-m lg:pt-[22px] lg:pb-2',
            { 'md:pt-[21px] md:pb-[78px] lg:pb-[101px]': isHomePage }
          )}
        >
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col lg:w-1/3 md:flex-row lg:flex-col md:mb-[4px]">
              <div className="relative flex flex-col justify-center md:w-1/2 lg:w-auto md:mb-[112px] lg:mb-0">
                <div className="relative flex flex-col justify-center w-max">
                  <FlagLabel
                    name="The floor plans"
                    textSize="md:text-20-26-0.67 xl:text-24-32-0.3 bg-beige"
                  />
                </div>
              </div>
              <div className="text-14-21-0.3 mt-[22px] md:mt-[1px] md:ml-[31px] lg:ml-[2px] lg:mt-[30px] mb-[43px] md:w-1/2 lg:w-[387px] lg:text-16-26-0.3">
                {/* <Markdown className="mt-10">
                  {floorplans.data.description}
                </Markdown> */}
                <p>
                  Images only tell half the story. See the layout of any condo
                  you like. We have everything under one roof to make your life
                  easier.
                </p>
                <p className="mt-[21px] lg:mt-[27px]">
                  90% of all properties available with floorplans.
                </p>
              </div>
            </div>
            <div className="flex lg:w-2/3">
              <div className="w-1/2">
                <Image src={floorplan1} alt="florplan1-image" />
              </div>
              <div className="w-1/2">
                <Image src={floorplan2} alt="florplan2-image" />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'flex items-center justify-center mx-auto mt-10 md:w-1/2 lg:w-1/3 lg:pl-6',
              {
                'md:mt-[43px] lg:mt-[106px] md:w-[324px] lg:w-[445px]':
                  isHomePage,
              }
            )}
          >
            <Button
              name="Start your search"
              href="/search?preConstruction=true&newConstruction=true&foreclosures=true&shortSales=true&existingCondos=true&forRent=true&forSale=true"
              classNameWidth={'md:h-[48px] lg:h-[63px]'}
            />
          </div>
        </section>
      }
    </>
  );
};

export default Floorplan;
